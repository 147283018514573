import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import produce from 'immer';

import CustomerConsentApiFetches from '~/api/customerConsent';
import { showLoading, hideLoading, showConfirm } from '~/utils/postMessage';
import { convertError } from '~/utils/converter';
import MESSAGES from '~/components/customer-consent/customerConsentMessage';
import {
  ICustomerConsentDetail,
  TCustomerConsentResponse,
} from '~/components/customer-consent/customer-consent-interface';
import { getV2AuthHeaders } from '~/utils/common';

const { getCustomerConsentDetail, getCustomerConsentsList, getCustomerConsentsListByWashId } =
  CustomerConsentApiFetches;

// Actions
const FETCH_CUSTOMER_CONSENTS_LIST_REQUEST = 'FETCH_CUSTOMER_CONSENTS_LIST_REQUEST';
const FETCH_CUSTOMER_CONSENTS_LIST_SUCCESS = 'FETCH_CUSTOMER_CONSENTS_LIST_SUCCESS';
const FETCH_CUSTOMER_CONSENTS_FAILURE = 'FETCH_CUSTOMER_CONSENTS_FAILURE';
const FETCH_CUSTOMER_CONSENTS_FAILURE_CLEAR = 'FETCH_CUSTOMER_CONSENTS_FAILURE_CLEAR';
const FETCH_CUSTOMER_CONSENT_DETAIL_REQUEST = 'FETCH_CUSTOMER_CONSENT_DETAIL_REQUEST';
const FETCH_CUSTOMER_CONSENT_DETAIL_SUCCESS = 'FETCH_CUSTOMER_CONSENT_DETAIL_SUCCESS';
const CUSTOMER_CONSENT_DETAIL_CLEAR = 'CUSTOMER_CONSENT_DETAIL_CLEAR';

const fetchCustomerConsentsListRequest = createAction(FETCH_CUSTOMER_CONSENTS_LIST_REQUEST);
const fetchCustomerConsentsListSuccess = createAction(FETCH_CUSTOMER_CONSENTS_LIST_SUCCESS);
const fetchCustomerConsentDetailRequest = createAction(FETCH_CUSTOMER_CONSENT_DETAIL_REQUEST);
const fetchCustomerConsentDetailSuccess = createAction(FETCH_CUSTOMER_CONSENT_DETAIL_SUCCESS);
const customerConsentDetailClear = createAction(CUSTOMER_CONSENT_DETAIL_CLEAR);
const fetchCustomerConsentsFailure = createAction(FETCH_CUSTOMER_CONSENTS_FAILURE);
const fetchCustomerConsentsFailureClear = createAction(FETCH_CUSTOMER_CONSENTS_FAILURE_CLEAR);

export {
  fetchCustomerConsentsListRequest,
  fetchCustomerConsentsFailureClear,
  fetchCustomerConsentDetailRequest,
  customerConsentDetailClear,
};

interface IInitialState {
  pending: boolean;
  data: {
    list: TCustomerConsentResponse[] | null;
    detail: ICustomerConsentDetail | null;
  };
  error: any;
}

const initialState: IInitialState = {
  pending: false,
  data: {
    list: null,
    detail: null,
  },
  error: null,
};

export const reducer = handleActions(
  {
    [FETCH_CUSTOMER_CONSENTS_LIST_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.data.list = initialState.data.list;
        draft.error = null;
      }),
    [FETCH_CUSTOMER_CONSENTS_LIST_SUCCESS]: (state, { payload }: any) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.data.list = payload;
        draft.error = null;
      }),
    [FETCH_CUSTOMER_CONSENTS_FAILURE]: (state, { payload: error }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
      }),
    [FETCH_CUSTOMER_CONSENT_DETAIL_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.data.detail = initialState.data.detail;
        draft.error = null;
      }),
    [FETCH_CUSTOMER_CONSENT_DETAIL_SUCCESS]: (state, { payload }: any) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.data.detail = payload;
        draft.error = null;
      }),
    [CUSTOMER_CONSENT_DETAIL_CLEAR]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.data.detail = initialState.data.detail;
        draft.error = null;
      }),
    [FETCH_CUSTOMER_CONSENTS_FAILURE_CLEAR]: (state) =>
      produce(state, (draft) => {
        draft.error = null;
      }),
  },
  initialState
);

// 고객 동의 목록 가져오기
function* watchFetchCustomerConsentsListSaga({
  payload,
}: {
  payload: { washId?: number; searchType?: 'wash' | 'recent' };
}) {
  const { washId, searchType } = payload;
  const { appVersion, http } = yield select((state) => state);

  const urlQueryParams = new URLSearchParams(window.location.search);
  const queryAppVersion = urlQueryParams.get('appVersion');

  const commonHeaders = getV2AuthHeaders(http.headers, true, appVersion || queryAppVersion);

  showLoading();

  try {
    let data: any;

    if (washId && searchType === 'wash') {
      data = (yield call(getCustomerConsentsListByWashId, { washId }, commonHeaders)).data;
    } else {
      data = (yield call(getCustomerConsentsList, commonHeaders)).data;
    }

    if (data.code === 0) {
      const { userCustomerConsentResponses } = data.data;
      yield put(fetchCustomerConsentsListSuccess(userCustomerConsentResponses));
    } else {
      // 구버전 업데이트 요청 컨펌
      if (data.code === MESSAGES.DEPRECATED_VERSION_ERROR_CODE) {
        const Window = window as any;
        Window.confirmCallback = function confirmCallback() {
          Window.location.href = 'laundrygo://goStore';
          Window.confirmCallback = null;
        };

        showConfirm({
          title: '',
          message: MESSAGES.DEPRECATED_VERSION,
          cancelTitle: '취소',
          confirmTitle: '확인',
          cancelCallback: null,
          confirmCallback: 'confirmCallback',
        });

        return;
      }

      yield put(fetchCustomerConsentsFailure(data));
    }
  } catch (error) {
    yield put(fetchCustomerConsentsFailure(error));
  } finally {
    hideLoading();
  }
}

// 고객 동의 상세 정보 가져오기
function* watchFetchCustomerConsentDetailSaga({ payload }) {
  const { userCustomerConsentId } = payload;
  const { headers } = yield select((state) => state.http);
  showLoading();

  try {
    const { data: responseData } = yield call(
      getCustomerConsentDetail,
      { userCustomerConsentId },
      getV2AuthHeaders(headers)
    );

    if (responseData.code === 0) {
      const { data } = responseData;
      yield put(fetchCustomerConsentDetailSuccess(data));
    } else {
      yield put(fetchCustomerConsentsFailure(responseData));
    }
  } catch (error) {
    yield put(fetchCustomerConsentsFailure(error));
  } finally {
    hideLoading();
  }
}

export const sagas = [
  takeLatest<any>(FETCH_CUSTOMER_CONSENTS_LIST_REQUEST, watchFetchCustomerConsentsListSaga),
  takeLatest<any>(FETCH_CUSTOMER_CONSENT_DETAIL_REQUEST, watchFetchCustomerConsentDetailSaga),
];
