import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import getConfig from 'next/config';

// import { HYDRATE } from 'next-redux-wrapper';
import fetcher from '../../../api/lib/fetcher';
import { HttpError } from '../../../types/Error';
import { convertError } from '../../../utils/converter';

const { publicRuntimeConfig } = getConfig();
const { V2_API } = publicRuntimeConfig;

// Actions
const FETCH_FRIEND_INVITE = 'FETCH_FRIEND_INVITE';
const FETCH_FRIEND_INVITE_SUCCESS = 'FETCH_FRIEND_INVITE_SUCCESS';
const FETCH_FRIEND_INVITE_FAILURE = 'FETCH_FRIEND_INVITE_FAILURE';

const fetchFriendInvite = createAction(FETCH_FRIEND_INVITE);
const fetchFriendInviteSuccess = createAction(FETCH_FRIEND_INVITE_SUCCESS);
const fetchFriendInviteFailure = createAction(FETCH_FRIEND_INVITE_FAILURE);

export { fetchFriendInvite };

const initialState = {
  pending: false,
  data: null,
  error: null,
};

export const reducer = handleActions(
  {
    [FETCH_FRIEND_INVITE]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
      }),
    [FETCH_FRIEND_INVITE_SUCCESS]: (state, { payload: { c, d, code, data, inviteCode } }: any) =>
      produce(state, (draft) => {
        const resCode = c || code;
        const resData = d || data;

        const { status } = resData;

        if (resCode > 0 || (status && status === 0)) {
          const { message } = resData;
          throw new HttpError(resCode, message);
        }
        resData.code = inviteCode;
        draft.pending = false;
        draft.data = { ...resData };
        draft.error = null;
      }),
    [FETCH_FRIEND_INVITE_FAILURE]: (state, { payload: error }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
      }),
  },
  initialState
);

function* watchFetchEvent({ payload: { inviteCode } }) {
  try {
    const url = `${V2_API}/v2/friend/${inviteCode}`;
    const result = yield call(fetcher.get, url);
    yield put(fetchFriendInviteSuccess({ ...result.data, inviteCode }));
  } catch (error) {
    yield put(fetchFriendInviteFailure(error));
  }
}

export const sagas = [takeLatest<any>(FETCH_FRIEND_INVITE, watchFetchEvent)];
