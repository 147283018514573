import { createAction, handleActions } from 'redux-actions';
import { all, call, select, takeLatest } from 'redux-saga/effects';
import CONSTANT from '~/utils/constant';

import {
  setHeaders as setFetcherHeaders,
  setAuthzationHeaders as setFetcherAuthzationHeaders,
} from '../../../api/lib/fetcher';

// Actions
const SET_HEADERS = 'SET_HEADERS';
const SET_AUTHORIZATION = 'SET_AUTHORIZATION';
const SET_AUTHORIZATION_ONLY = 'SET_AUTHORIZATION_ONLY';
// Action Creates
const setHeaders = createAction(SET_HEADERS);
const setAuthorizationHeaders = createAction(SET_AUTHORIZATION);
const setAuthorizationOnly = createAction(SET_AUTHORIZATION_ONLY);

export { setHeaders, setAuthorizationHeaders, setAuthorizationOnly };

// Reducer
const initialState = {};

export const reducer = handleActions(
  {
    [SET_HEADERS]: (state, { payload: { headers } }: any) => {
      if (headers.mauthorization) {
        headers[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN] = headers.mauthorization;
        delete headers.mauthorization;
      }

      if (headers['muser-agent']) {
        headers['user-agent'] = headers['muser-agent'];
        // delete headers['muser-agent']; /pages/api/friend/event/index.ts 의 10번째 줄 muser-agent 없는 문제 수정 : 2021.08.24 이현국
      }

      return headers ? { ...headers } : state;
    },
    [SET_AUTHORIZATION]: (state, { payload: { authorization } }: any) => {
      const newHeaders: any = { ...state };

      if (authorization) {
        newHeaders[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN] = authorization;
      } else {
        delete newHeaders[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN];
      }

      setFetcherAuthzationHeaders(newHeaders);
      return { ...newHeaders };
    },
    [SET_AUTHORIZATION_ONLY]: (state, { payload: { authorization } }: any) => {
      const newState: any = { ...state };

      if (newState[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN] === authorization) {
        return {
          ...newState,
          'X-LG-ACCESS-TOKEN': authorization,
        };
      }

      return {
        ...newState,
        authorization,
        'X-LG-ACCESS-TOKEN': authorization,
      };
    },
  },
  initialState
);

// Sagas
function* watchSetHeaders({ payload: { headers, isServer } }: any) {
  const httpHeaders = isServer ? headers : yield select((state) => state.http.headers);
  if (!isServer) {
    // delete httpHeaders['user-agent'];
    delete httpHeaders.cookie;
  }

  yield all([call(setFetcherHeaders, httpHeaders, isServer)]);
  if (httpHeaders[CONSTANT.HEADERS.HEADER_ACCESS_TOKEN] !== undefined) {
    setFetcherAuthzationHeaders(httpHeaders);
  }
}

export const sagas = [takeLatest(SET_HEADERS, watchSetHeaders)];
