import React from 'react';

const MESSAGES = {
  DEPRECATED_VERSION_ERROR_CODE: 62000,
  MAIN_CONTENT_DESCRIPTION:
    '런드리고 서비스를 진행하는 과정에 고객님께 안내사항이 있습니다. 아래 항목을 확인해주세요.',
  MAIN_CONTENT_EMPTY_DESCRIPTION: (
    <>
      지난 서비스에 대한 항목은 <br /> 런드리고 앱의 이용내역 탭에서 확인해주세요.
    </>
  ),
  EMPTY_REASON: '답변을 선택해주세요',
  EMPTY_CONFIRM: '유의사항을 확인해주세요',
  SUCCESS_SUBMIT: '답변이 전달되었습니다',
  NETWORK_ERROR: '네트워크가 불안정합니다',
  RESPONSE_TIME_PASSED: '답변 요청 시간이 지나, 답변을 전달할 수 없습니다.',
  CONFIRM_CHECK: '(확인) 답변한 내용은 이후 수정이 불가능합니다.',
  DEPRECATED_VERSION: '더 나은 서비스를 이용하기 위해 최신 버전으로 업데이트 해주세요!',
  LOGIN_REQUIRED: '로그인이 필요한 서비스 입니다',
  LOGIN_CONFIRM: '로그인 하시겠습니까?',
};

export default MESSAGES;
