import { combineReducers } from 'redux';
import { reducers as http } from '~/redux/modules/http';
import { reducers as friend } from '~/redux/modules/friend';
import { reducers as m } from '~/redux/modules/m';
import { reducers as cms } from '~/redux/modules/cms';
import { reducers as store } from '~/redux/modules/store';
import { reducers as customerConsent } from '~/redux/modules/customer-consent';
import { reducers as price } from '~/redux/modules/price';

export default combineReducers({ http, friend, m, cms, store, customerConsent, price });
