import { all } from 'redux-saga/effects';
import { sagas as http } from '~/redux/modules/http';
import { sagas as friend } from '~/redux/modules/friend';
import { sagas as m } from '~/redux/modules/m';
import { sagas as cms } from '~/redux/modules/cms';
import { sagas as store } from '~/redux/modules/store';
import { sagas as customerConsent } from '~/redux/modules/customer-consent';

function* rootSaga() {
  yield all([...http, ...friend, ...m, ...cms, ...store, ...customerConsent]);
}

export default rootSaga;
