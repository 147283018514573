import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';

import fetcher from '../../../api/lib/fetcher';
import { HttpError } from '../../../types/Error';
import { convertError } from '../../../utils/converter';
import { INotice } from '../../../interface/cms/notice-interface';

// Actions
const FETCH_CMS_NOTICE = 'FETCH_CMS_NOTICE';
const FETCH_CMS_NOTICE_SUCCESS = 'FETCH_CMS_NOTICE_SUCCESS';
const FETCH_CMS_NOTICE_FAILURE = 'FETCH_CMS_NOTICE_FAILURE';

const fetchCmsNotice = createAction(FETCH_CMS_NOTICE);
const fetchCmsNoticeSuccess = createAction(FETCH_CMS_NOTICE_SUCCESS);
const fetchCmsNoticeFailure = createAction(FETCH_CMS_NOTICE_FAILURE);

export { fetchCmsNotice, fetchCmsNoticeSuccess };

const initialState = {
  pending: false,
  list: [],
  error: null,
  page: 0,
  more: true,
} as {
  pending: boolean;
  list: INotice[];
  error: null;
  page: number;
  more: boolean;
};

export const reducer = handleActions(
  {
    [FETCH_CMS_NOTICE]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
      }),
    [FETCH_CMS_NOTICE_SUCCESS]: (state, { payload: { c, d } }: any) =>
      produce(state, (draft) => {
        if (c > 0) {
          const { message } = d;
          throw new HttpError(c, message);
        }
        draft.pending = false;
        draft.list = [...state.list, ...d.content];
        draft.page = state.page + 1;
        draft.more = d.content.length === 20;
        draft.error = null;
      }),
    [FETCH_CMS_NOTICE_FAILURE]: (state, { payload: error }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
      }),
  },
  initialState
);

function* watchFetchNotice({ payload: { url } }) {
  try {
    const result = yield call(fetcher.get, url);
    yield put(fetchCmsNoticeSuccess({ ...result.data }));
  } catch (error) {
    yield put(fetchCmsNoticeFailure(error));
  }
}

export const sagas = [takeLatest<any>(FETCH_CMS_NOTICE, watchFetchNotice)];
