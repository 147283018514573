import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';

import fetcher from '../../../api/lib/fetcher';
import { HttpError } from '../../../types/Error';
import { convertError } from '../../../utils/converter';

// Actions
const FETCH_FRIEND_EVENT = 'FETCH_FRIEND_EVENT';
const FETCH_FRIEND_EVENT_SUCCESS = 'FETCH_FRIEND_EVENT_SUCCESS';
const FETCH_FRIEND_EVENT_FAILURE = 'FETCH_FRIEND_EVENT_FAILURE';

const fetchFriendEvent = createAction(FETCH_FRIEND_EVENT);
const fetchFriendEventSuccess = createAction(FETCH_FRIEND_EVENT_SUCCESS);
const fetchFriendEventFailure = createAction(FETCH_FRIEND_EVENT_FAILURE);

export { fetchFriendEvent, fetchFriendEventSuccess };

const initialState = {
  pending: false,
  data: null,
  error: null,
};

export const reducer = handleActions(
  {
    [FETCH_FRIEND_EVENT]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
      }),
    [FETCH_FRIEND_EVENT_SUCCESS]: (state, { payload: { c, d, code, data } }: any) =>
      produce(state, (draft) => {
        const resCode = c || code;
        const resData = d || data;

        if (resCode > 0) {
          const { message } = resData;
          throw new HttpError(resCode, message);
        }
        draft.pending = false;
        draft.data = { ...resData };
        draft.error = null;
      }),
    [FETCH_FRIEND_EVENT_FAILURE]: (state, { payload: error }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
      }),
  },
  initialState
);

function* watchFetchEvent({ payload: { url, rankUrl } }) {
  try {
    const result = yield call(fetcher.get, url);
    const resultRanking = yield call(fetcher.get, rankUrl);

    const resResultData = result.data.d || result.data.data;

    const combineResult = {
      ...result.data,
    };

    combineResult[result.data.d ? 'd' : 'data'] = {
      ...resResultData,
      inviteRanking: resultRanking.data,
    };

    yield put(fetchFriendEventSuccess({ ...combineResult }));
  } catch (error) {
    yield put(fetchFriendEventFailure(error));
  }
}

export const sagas = [takeLatest<any>(FETCH_FRIEND_EVENT, watchFetchEvent)];
