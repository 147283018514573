/* eslint-disable no-undef */
import { configureMessageHandler, closeWindow } from '../utils/postMessage';

/**
 * 전역(window)에서 공통으로 사용하는 기능 설정
 */
function configureWindow(window) {
  configureMessageHandler();
  window.closeWindow = closeWindow;
  window.closeWindowCallback = function closeWindowCallback() {
    return null;
  };
}

// eslint-disable-next-line import/prefer-default-export
export { configureWindow };
