import getConfig from 'next/config';
import axios from '~/api/lib/fetcher';
import {
  IGetCustomerConsentDetailResponse,
  IGetCustomerConsentsListByWashIdResponse,
  IGetCustomerConsentsListResponse,
  IPostCustomerConsentRequest,
} from '~/components/customer-consent/customer-consent-interface';

const { publicRuntimeConfig } = getConfig();
const { IS_LOCAL, V2_API } = publicRuntimeConfig;

const localHeaders = {
  'x-lg-access-token':
    '2adbd8e17bcbbf219a623be9d559979dbe91652d8aa10431d6e5e1567138a23665dbba386236551ffc99c21dc6a0a0ad51f7fad825b57e46bf4acf99402c5cf7e7aec78bb5ab37757133e5f30704ab532629d087281941c71c9f6710d9c9ac91a19cdfaef29b56a43a74f66c7b72fd4cf20fe1ee33d8cefc2b6af39aae67895a0198d83d48ad95f91ce709a52a6ebe78a7a22f041be8f7d74212c697d27bdb8fae6933bc503e47286fac63fab5d79982',
  'device-id': 'YWZUWKORJO0YGYSJUW0ZNTZL74I76BO3LDBOXIDJ',
  'muser-agent': 'LAUNDRYGO-DEV/1.4.0/268 (SM-G970N; Android 11) Mobile GooglePlay',
  'X-LG-APP-VERSION': '1.7.0',
};

const CustomerConsentApiFetches = {
  // 고객동의 상세 조회
  getCustomerConsentDetail: (
    { userCustomerConsentId },
    headers
  ): Promise<IGetCustomerConsentDetailResponse> => {
    return axios.get(`${V2_API}/v2/user-customer-consents/${userCustomerConsentId}`, {
      headers: IS_LOCAL ? localHeaders : headers,
    });
  },
  // 고객 답변 제출
  postCustomerConsent: (
    { userCustomerConsentId, lsfCustomerConsentReasonButtonId }: IPostCustomerConsentRequest,
    headers
  ) => {
    return axios.post(
      `${V2_API}/v2/user-customer-consents/${userCustomerConsentId}`,
      { lsfCustomerConsentReasonButtonId },
      {
        headers: IS_LOCAL ? localHeaders : headers,
      }
    );
  },
  // 유저별 최근 3일 고객동의 건 조회
  getCustomerConsentsList: (headers): Promise<IGetCustomerConsentsListResponse> => {
    return axios.get(`${V2_API}/v2/user-customer-consents/user`, {
      headers: IS_LOCAL ? localHeaders : headers,
    });
  },
  // 유저별 고객동의 확인 필요 여부 YN
  getCustomerConsentsCheck: (headers) => {
    return axios.get(`${V2_API}/v2/user-customer-consents/user/check`, {
      headers: IS_LOCAL ? localHeaders : headers,
    });
  },
  // 수거건별 고객동의 목록 api
  getCustomerConsentsListByWashId: (
    { washId },
    headers
  ): Promise<IGetCustomerConsentsListByWashIdResponse> => {
    return axios.get(`${V2_API}/v2/user-customer-consents/wash/${washId}`, {
      headers: IS_LOCAL ? localHeaders : headers,
    });
  },
};

export default CustomerConsentApiFetches;
