import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';

import fetcher from '../../../api/lib/fetcher';
import { HttpError } from '../../../types/Error';
import { convertError } from '../../../utils/converter';

// Actions
const FETCH_CMS_FAQ = 'FETCH_CMS_FAQ';
const FETCH_CMS_FAQ_SUCCESS = 'FETCH_CMS_FAQ_SUCCESS';
const FETCH_CMS_FAQ_FAILURE = 'FETCH_CMS_FAQ_FAILURE';

const fetchCmsFaq = createAction(FETCH_CMS_FAQ);
const fetchCmsFaqSuccess = createAction(FETCH_CMS_FAQ_SUCCESS);
const fetchCmsFaqFailure = createAction(FETCH_CMS_FAQ_FAILURE);

export { fetchCmsFaq, fetchCmsFaqSuccess };

const initialState = {
  pending: false,
  list: [],
  error: null,
};

export const reducer = handleActions(
  {
    [FETCH_CMS_FAQ]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
      }),
    [FETCH_CMS_FAQ_SUCCESS]: (state, { payload: { c, d } }: any) =>
      produce(state, (draft) => {
        if (c > 0) {
          const { message } = d;
          throw new HttpError(c, message);
        }
        draft.pending = false;
        draft.list = d;
        draft.error = null;
      }),
    [FETCH_CMS_FAQ_FAILURE]: (state, { payload: error }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
      }),
  },
  initialState
);

function* watchFetchFaq({ payload: { url } }) {
  try {
    const result = yield call(fetcher.get, url);
    yield put(fetchCmsFaqSuccess({ ...result.data }));
  } catch (error) {
    yield put(fetchCmsFaqFailure(error));
  }
}

export const sagas = [takeLatest<any>(FETCH_CMS_FAQ, watchFetchFaq)];
