import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import produce from 'immer';

const ADD_LAUNDRY = 'ADD_LAUNDRY';
const ADD_WASH = 'ADD_WASH';
const SUBSTRACT_LAUNDRY = 'SUBSTRACT_LAUNDRY';
const REMOVE_LAUNDRY = 'REMOVE_LAUNDRY';

export const addLaundry = createAction<TPayload>(ADD_LAUNDRY);
export const addWash = createAction<TPayload>(ADD_WASH);
export const substractLaundry = createAction<TPayload>(SUBSTRACT_LAUNDRY);
export const removeLaundry = createAction<TPayload>(REMOVE_LAUNDRY);

export type TLaundryType = 'DRYCLEAN' | 'WASH' | 'PREMIUM';
type TLaundry = { name: string; price: number; count: number; showCount?: boolean };
type TPayload = { type: TLaundryType; laundry: Omit<TLaundry, 'count'> };

type TPriceEstimationState = {
  addedLaundryInfoList: { type: TLaundryType; label: string; laundryList: TLaundry[] }[];
};

const initialState: TPriceEstimationState = {
  addedLaundryInfoList: [
    { type: 'DRYCLEAN', label: '개별클리닝', laundryList: [] },
    { type: 'WASH', label: '생활빨래', laundryList: [] },
    { type: 'PREMIUM', label: '프리미엄', laundryList: [] },
  ],
};

export const reducer = handleActions<TPriceEstimationState, TPayload>(
  {
    [ADD_LAUNDRY]: (state, { payload }: { payload: TPayload }) =>
      produce(state, (draft) => {
        const { type, laundry } = payload;
        const { name } = laundry;

        const targetLaundryInfo = draft.addedLaundryInfoList.find(
          ({ type: laundryType }) => laundryType === type
        );
        const targetLaundry = targetLaundryInfo?.laundryList.find((item) => item.name === name);

        if (!targetLaundryInfo) {
          return;
        }

        if (targetLaundry) {
          targetLaundry.count += 1;
        } else {
          targetLaundryInfo.laundryList.push({ ...laundry, count: 1 });
        }
      }),
    [ADD_WASH]: (state, { payload }) =>
      produce(state, (draft) => {
        const { type, laundry } = payload;

        const targetLaundryInfo = draft.addedLaundryInfoList.find(
          ({ type: laundryType }) => laundryType === type
        );
        const targetLaundry = targetLaundryInfo?.laundryList[0];

        if (!targetLaundryInfo) {
          return;
        }

        if (targetLaundry) {
          targetLaundry.name = laundry.name;
          targetLaundry.price = laundry.price;
        } else {
          targetLaundryInfo.laundryList.push({ ...laundry, count: 1 });
        }
      }),
    [SUBSTRACT_LAUNDRY]: (state, { payload }) =>
      produce(state, (draft) => {
        const { type, laundry } = payload;
        const { name } = laundry;

        const targetLaundryInfo = draft.addedLaundryInfoList.find(
          ({ type: laundryType }) => laundryType === type
        );
        const targetLaundry = targetLaundryInfo?.laundryList.find((item) => item.name === name);

        if (!targetLaundryInfo) {
          return;
        }

        if (targetLaundry) {
          targetLaundry.count -= 1;
          if (targetLaundry.count === 0) {
            targetLaundryInfo.laundryList = targetLaundryInfo.laundryList.filter(
              (item) => item.name !== laundry.name
            );
          }
        }
      }),
    [REMOVE_LAUNDRY]: (state, { payload }) =>
      produce(state, (draft) => {
        const { type, laundry } = payload;
        const { name } = laundry;

        const targetLaundryInfo = draft.addedLaundryInfoList.find(
          ({ type: laundryType }) => laundryType === type
        );
        const targetLaundry = targetLaundryInfo?.laundryList.find((item) => item.name === name);

        if (!targetLaundryInfo) {
          return;
        }

        if (targetLaundry) {
          targetLaundryInfo.laundryList = targetLaundryInfo.laundryList.filter(
            (item) => item.name !== laundry.name
          );
        }
      }),
  },
  initialState
);

const laundryListSelector = (state) => state.price.estimation.addedLaundryInfoList;

export const selectLaundryList = createSelector(laundryListSelector, (addedLaundryInfoList) => {
  return addedLaundryInfoList;
});

export const selectTotalInfo = createSelector(laundryListSelector, (addedLaundryInfoList) => {
  return addedLaundryInfoList
    .flatMap(({ laundryList }) => laundryList)
    .reduce(
      (acc, cur) => {
        return {
          totalPrice: acc.totalPrice + cur.price * cur.count,
          totalCount: acc.totalCount + cur.count,
        };
      },
      { totalPrice: 0, totalCount: 0 }
    );
});
